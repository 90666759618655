@mixin font-size($font-size: 16) {
	font-size : #{$font-size}px;
	font-size : #{$font-size / 10}rem;
}

@mixin input_mobile() { 
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.hidden { display: none !important;  }

.clear {
	clear: both;
}

.clearfix {
	@include clearfix;
}

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

@mixin hide-text() {
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

.hide-text {
	@include hide-text();
}

.img-responsive {
	max-width: 100%;
	height: auto;
}

.relative {
	position: relative;
}

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin center($width: null, $height: null) {
	position: absolute;
	top: 50%;
	left: 50%;

	@if not $width and not $height {
		transform: translate(-50%, -50%);
	} @else if $width and $height {
		width: $width;
		height: $height;
		margin: -($width / 2) #{0 0} -($height / 2);
	} @else if not $height {
		width: $width;
		margin-left: -($width / 2);
		transform: translateY(-50%);
	} @else {
		height: $height;
		margin-top: -($height / 2);
		transform: translateX(-50%);
	}
}

@mixin icon() {
	font-family: $font-icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin aspect-ratio($width, $height) {
	position: relative;
	
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	
	> div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

#__bs_notify__ {
	width: 170px !important;
	right: 50% !important;
	padding: 10px !important;
	margin-right: -85px !important;
	background-color: rgba(#000, 0.3) !important;
	border-radius: 0 !important;
	font-size: 12px !important;
}

// DEBUG
.debug-grey {
	background-color: #DDD !important;
}
.debug-white {
	background-color: white !important;
}
.debug-black {
	background-color: black !important;
}
.debug-red {
	background-color: red !important;
}
.debug-green {
	background-color: green !important;
}
.debug-blue {
	background-color: blue !important;
}
.debug-yellow {
	background-color: yellow !important;
}
.debug-purple {
	background-color: purple !important;
}
.debug-orange {
	background-color: orange !important;
}