html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
	cursor: pointer !important;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

.number-link a{
	color: $color-1 !important;
	text-decoration: none !important;
	border: none !important;
}

/* 
########   ########   ##     ##  ########      ###     ##         
##     ##  ##         ##     ##  ##           ## ##    ##         
##     ##  ##         ##     ##  ##          ##   ##   ##         
########   ######     ##     ##  ######     ##     ##  ##         
##   ##    ##          ##   ##   ##         #########  ##         
##    ##   ##           ## ##    ##         ##     ##  ##         
##     ##  ########      ###     ########   ##     ##  ########   
*/

.js .scroll-reveal {
	&.box {
		opacity: 0;
	}

	&.comma {
		opacity: 0;
		transform-origin: 100% 0;
	}
	
	&.editor-text {
		p, li {
			opacity: 0;
		}
		// span {
		// 	opacity: 0;
		// }
	}

	.gl__content {
		opacity: 0;
	}

	&.images {
		[data-reveal="image"] {
			opacity: 0;
		}
	}

	// .vertical-title {
	// 	opacity:0;
	// }
		
	&.text {
		span {
			display: inline-block;
			overflow: hidden;
			opacity: 0;

			.split-line {
				overflow: hidden;
			}
		}

		svg {
			opacity: 0;
		}
	}

	&.title {
		opacity: 0;
	}

	&.lined-text {
		p {
			opacity: 0;
		}
	}

	&.vertical-title {
		opacity: 0;
	}

	// .stagger-show-item {
	// 	.timeline__logo {
	// 		position: relative;
	// 		opacity: 0;
	// 		transform: translateY(10%);
	// 		transition: transform 0.3s $ease, opacity 0.3s $ease;
	// 	}

	// 	.timeline__year {
	// 		position: relative;
	// 		opacity: 0;
	// 		transform: translateY(10%);
	// 		transition: transform 0.3s $ease 0.2s, opacity 0.3s $ease 0.2s;
	// 	}

	// 	.timeline__btn {
	// 		position: relative;
	// 		opacity: 0;
	// 		transform: translateY(10%);
	// 		transition: transform 0.3s $ease 0.4s, opacity 0.3s $ease 0.4s;
	// 	}

	// 	&.show {
	// 		.timeline__logo,
	// 		.timeline__year,
	// 		.timeline__btn {
	// 			opacity: 1;
	// 			transform: translateY(0);
	// 		}
	// 	}
	// }
}

/* 
##            ###     ##    ##    #######   ##     ##  ########   
##           ## ##     ##  ##    ##     ##  ##     ##     ##      
##          ##   ##     ####     ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
##         #########     ##      ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
########   ##     ##     ##       #######    #######      ##      
*/

.page {
	@include clearfix;
	width: 100%;
	overflow: hidden;

}

.wrapper {
	width: 100%;
	margin: 0px auto;
	padding: 30px;
	position: relative;

	@include media-breakpoint-up(md) {
		max-width: 760px;
	}
	@include media-breakpoint-up(lg) {
		max-width: 1000px;
	}
	@include media-breakpoint-up(xl) {
		max-width: 1200px;
	}
}

.block-padding {

	padding: 40px 0px;

	@include media-breakpoint-up(md) {
		padding: 40px 80px;
	}
	@include media-breakpoint-up(lg) {
		padding: 60px;
	}
	@include media-breakpoint-up(xl) {
		padding: 80px;
	}
	
	position: relative;
}

.vertical-padding {
	@include media-breakpoint-up(md) {
		padding: 20px 0px;
	}
	@include media-breakpoint-up(lg) {
		padding: 40px 0px;
	}
	
}

.display-none {
	display: none;
}


/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.editor-text {
	color: $color-text;

	p {
		font-size: 15px;
		line-height: 25px;
		padding-top: 15px;

		.section-light &, .section-medium &{
			color: $color-1;
		}

		.section-dark & {
			color: $white;
		}
	}

	em > p {
		font-size: 16px;

		@include media-breakpoint-up(md) {
			font-size: 18px;
		}
		
		line-height: 25px;
		padding:0;
	}

	strong {
		font-family: $font-reg !important;
	}

	ul {
		padding-top: 15px;
	}

	li {

		@include media-breakpoint-down(sm) {
			padding-left: 30px;
			&:after {
				left:0px!important;
			}
		}

		font-size: 15px;
		line-height: 25px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			background-color: $color-2;
			width: 17px;
			height: 1px;
			left :-25px;
			top:13px;
		}

		&.col-lg-4 {
			@include media-breakpoint-down(sm) {
				padding-left: 0;
			}
			
			&:after {
				visibility:hidden;
			}
		}

		.section-dark & {
			color: $white;
		}
	}

	// h2 {
	// 	margin-bottom: 1.5em;
	// 	font-family: $font-alt;
	// 	@include font-size(16);
	// }

	// h3 {
	// 	margin-bottom: 1em;
	// 	font-weight: 600;
	// }
}

/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
.header-main {
	margin-top: 0;
	padding-top:0;
	position: relative;
}

.banner {
	width: 100%;
	height: 30vh;
	background: transparent url('#{$image-path}home-img.jpg') 0 0 no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;

	@include media-breakpoint-up(md) {
		height: 50vh;
	}

	&:after {
		content:"";
		display: block;
		width: 100%;
		height: 100%;
		position:absolute; 
		right:0;
		bottom: 0;
		background: linear-gradient(to top, transparent 0%, transparent 70%, rgba($color-1, 0.7) 100%);
	}

	.home & {
		@include media-breakpoint-up(md) {
			height: 80vh !important;
		}
	}
}

.nav-container {
	position: absolute;
	z-index: 3;
	top:0px;
	left:0px;
	right:30px;
	right:0;
	@include clearfix;

	// background-color: $color-1;

	@include media-breakpoint-up(lg) {
		position: absolute;
		top:0;
		left: 0;
		transform: translateY(0);
	

		&.scrolled {
			transform: translateY(-100%);
		}
		&.stuck {
			position: fixed;
			height: 75px;
			transform: translateY(0);
			background-color: $white;

		}
	}
}

.logo {
	float: left;
	width: 130px;
	position: absolute;
	top:10px;
	height: 63px;
	background: transparent url('#{$image-path}logo-frasie.png') center center no-repeat;
	background-size: 130px 63px;
	
	@include hidpi {
		background-image: url('#{$image-path}logo-frasie@2x.png');
	}


	@include media-breakpoint-up(md) {
		position: relative;
		width: 170px;
		height: 70px;
		background-size: 170px 70px;
	}

	.stuck & {
		@include media-breakpoint-up(lg) {
			width: 130px;
			height: 63px;
			position: absolute;
			top:10px;
			background: transparent url('#{$image-path}logo-frasie_scrolled.png') no-repeat 0 0;
			background-size: 130px 63px;
			background-position: center;
			
			@include hidpi {
				background-image: url('#{$image-path}logo-frasie_scrolled@2x.png');
			}
		}
	}

}

.nav-main {

	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		right: 0;

		background-color: $color-2;

		transition: transform 0.3s $ease, opacity 0.3s $ease;
		transform: translateX(100%);

		@include media-breakpoint-down(sm) {
			text-align: center;

			ul {
				@include center();
				width: 100%;
			}
		}

		@include media-breakpoint-up(md) {
			width: 40%;
			// padding: 100px 20px 0 0;
			text-align: right;

			ul {
				@include center();
			}
		}
		
		.nav-opened & {
			transform: translateX(0);
		}

		.menu-item {
			margin: 15px 0;
			display: block;

			&.current {
				a {
					color:$color-1;
				}
			}
		}

		a {
			display: block;
			padding: 10px;
			text-align: center;

			font-family: $font-base;
			text-transform: uppercase;
			@include font-size(16);
			color: $color-1;

			.no-touchevents &:hover,
			.no-touchevents &:focus,
			.touchevents &:active {
				color: $color-1;
			}
		}
	}

	a {
		color: $white;
	}
	

	@include media-breakpoint-up(lg) {
		padding: 35px 0px;
		display: block;
		float: right;

		.stuck & {
			padding: 0px 0px;
		}
	
		.menu-item {	
			padding: 0px 20px 10px 20px;
			display: inline-block;

			vertical-align: middle;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-moz-osx-font-smoothing: grayscale;
			position: relative;
			overflow: hidden;
			
			&:before {
				content: "";
				position: absolute;
				z-index: -1;
				// left: 100%;
				right: 100%;
				bottom: 0;
				background: $white;
				height: 1px;
				-webkit-transition-property: left, right;
				transition-property: left, right;
				-webkit-transition-duration: 0.3s;
				transition-duration: 0.3s;
				-webkit-transition-timing-function: ease-out;
				transition-timing-function: ease-out;
			}
			&.current a {
				color: $color-2;
			}

			.stuck & {
				&:before {
					background: $color-1;
				}

				a {
					color: $color-1;
				}

				&.current {
					&:before {
						left: 0;
						right: 0;
						background: $color-2;
					}
					a {
						color:$color-2;

					}
				}
			}
		}
		
		

		.menu-item:hover:before, .menu-item:focus:before, .menu-item:active:before {
			left: 0;
			right: 0;
		}
	}
}


.nav-main__open {
	@include media-breakpoint-down(sm) {
		right: 10px !important;
		top:10px !important;
	}
	@include media-breakpoint-down(md) {
		display: block;
		width: 50px;
		height: 50px;
		position: fixed;
		top: 40px;
		right: 50px;

		// background-color: #fff;
		background-color: rgba($color-2, 0.7);
		// border-radius: 50%;

		transition: transform 0.2s $ease, opacity 0.2s $ease, background-color 0.2s $ease;

		svg {
			display: block;
			width: 40px;
			height: 40px;
			fill: $white;

			position: absolute;
			top: 9px;
			left: 5px;

			transition: transform 0.2s $ease, color 0.2s $ease, opacity 0.2s $ease;
		}

		.no-touchevents &:hover,
		.no-touchevents &:focus,
		.touchevents &:active {
			background-color: $color-1;

			svg {
				fill: #fff;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
.nav-main__close {
	@include media-breakpoint-down(md) {
		display: block;
		width: 50px;
		height: 50px;
		position: fixed;
		top: 30px;
		right: 50px;
		opacity: 0;

		// background-color: $color-2;
		// border-radius: 50%;

		transition: background-color 0.2s $ease, opacity 0 $ease;

		&:before {
			display: block;
			line-height: 1;
			content: "×";
			text-align: center;
			font-family: $font-base;
			font-weight: 200;

			color: #fff;
			@include font-size(50);

			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);

			transition: color 0.2s $ease, opacity 0.3s $ease;

		}

		.no-touchevents &:hover,
		.no-touchevents &:focus,
		.touchevents &:active {
			// background-color: #fff;

			&:before {
				color: $color-1;
			}
		}



		span {
			display: none;
		}

		.nav-opened & {
			opacity: 1;
			transition: opacity 0.2s $ease 0.2s;
		}
	}

	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}



/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/

.scroll-top {
	display: block;
	width: 50px;
	height: 50px;
	
	position: fixed;
	bottom: 10px;
	right: 10px;

	z-index: 2;
	background-color: rgba($color-2, 0.7);

	opacity:0;
	transition: transform 0.2s $ease, opacity 0.2s $ease, background-color 0.2s $ease;

	@include media-breakpoint-up(md) {
		bottom: 30px;
		right: 50px;	
	}

	&.scrolled-btn {
		transition: opacity 0.2s linear;
		opacity:1;

	}

	svg {
		display: block;
		width: 45px;
		height: 45px;
		fill: $white;

		position: absolute;
		top: -2px;
		left: 2px;

		transition: transform 0.2s $ease, color 0.2s $ease, opacity 0.2s $ease;
	}
	span {
		display: block;
		position: absolute;
		bottom: 10px;
		left:10px;
		color: $white;
	}

	.no-touchevents &:hover,
	.no-touchevents &:focus,
	.touchevents &:active {
		background-color: $color-1;

		svg {
			fill: #fff;
		}
	}
}

	// @include media-breakpoint-up(lg) {
	// 	display: none;
	// }


.title-lev1 {
	font-family: $font-bold;
	font-size: 27px;
	line-height: 35px;
	padding-bottom: 10px;

	.section-light &, .section-medium & {
		color: $color-1;
	}

	.section-dark & {
		color: $white;
	}
}

.title-lev2 {
	font-family: $font-bold;
	font-size: 20px;
	padding-top: 5px;
	letter-spacing: 0.3px;
	line-height: 30px;
	color:$color-2;
}

.big--title {
	margin-top: 27vh;
	font-size: 25px;
	text-transform: uppercase;
	position: absolute;
	left: 50vw;
	text-align: center;
	transform: translate(-50%,-50%);

	@include media-breakpoint-up(sm) {
		font-size: 35px;
	}
	@include media-breakpoint-up(md) {
		font-size: 50px;
		margin-top: 25vh;
	}

	// @include media-breakpoint-up(lg) {
	// 	margin-top: 35vh;
	// }

	.home & {
		@include media-breakpoint-up(md) {
			margin-top: 40vh;
		}
	}

}

.vertical-title {
	display: inline-block;
	padding: 0 40px 20px 0;
	margin-bottom: 20px;
	opacity:1;

	border-bottom: 1px solid $color-1;

	text-transform: uppercase;
	letter-spacing: 0.4em;
	font-size: 12px;
	line-height: 1;

	@include media-breakpoint-up(lg) {
		font-size: 15px;
		padding: 0 0 20px 40px;
		margin: 0;
		position: absolute;
		top: 5px;
		left: -20px;
		
		transform-origin: 100% 100%;
		transform:  translate(-100%, -100%) rotate(-90deg);	
		// transform: rotate(-90deg);
	}

	
	.section-light & {
		color: $color-2;
		border-bottom: 1px solid $color-2;
	}

	.section-medium & {
		color: $color-1;
		border-bottom: 1px solid $color-1;
	}

	.section-dark & {
		color: $white;
		border-bottom: 1px solid $white;
	}
	
}

.subtitle {
	font-family: $font-bold;
	color: $white; 
	padding-top: 20px;
	display: block;
	font-size: 20px;

	@include media-breakpoint-down(md) {
		padding-bottom: 30px;
	}
}

.smart-link {
	// text-decoration: none!important;
	border-bottom: none !important;
	.title-lev2 & {
		color: $color-2 !important;
	}
	.section-medium &, .section-light & {
		color: $color-1;
	} 
	.section-dark & {
		color: $white;
	}

	.no-touchevents &:hover,
	.no-touchevents &:focus,
	.touchevents &:active {
		// text-decoration: none;
	}

	&:hover {
		// text-decoration: underline;
		// text-decoration-style: dotted;
		color: $color-2;
		// border-bottom: dotted 1px $color-3 !important;
	}
}



/* 
   ###     ########    #######   ##     ##  ########   
  ## ##    ##     ##  ##     ##  ##     ##     ##      
 ##   ##   ##     ##  ##     ##  ##     ##     ##      
##     ##  ########   ##     ##  ##     ##     ##      
#########  ##     ##  ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##     ##     ##      
##     ##  ########    #######    #######      ##      
 */

.about-img-container {
	display: none;
	width: 100%;
	height: 400px;

	.home &, .eau-industrielle &, .materiel &, .industrie & {
		background: transparent url('#{$image-path}img-1.jpg') no-repeat;
		background-position: center;
		background-size: 100% auto;
	}

	.traitement &, .eaux-usees &, .forage &, .epuration & {

		background: transparent url('#{$image-path}video-img.jpg') no-repeat;
		background-position: center;
		background-size: 100% auto;
	}

	@include media-breakpoint-up(md) {
		width: 300px;
		height: 500px;
		margin: 0px auto;
	}

	@include media-breakpoint-up(lg) {
		display: block;
		width: 400px;
		height: 600px;
	}
	

}
.advantage-img-container {

	width: 100%;
	height: 200px;
	background: transparent url('#{$image-path}img-2.jpg') 0 0 no-repeat;
	background-position: center;
	background-size: 100% auto;
	
	@include media-breakpoint-up(sm) {
		height: 300px;
	}
			// box-shadow: 0px 0px 20px rgba(#000,0.3);
	@include media-breakpoint-up(md) {
		height: 400px;
	}
	@include media-breakpoint-up(lg) {
		background-size: auto 100%;
		position: absolute;
		left:0;
		width: 500px;
		height: 310px;
		// box-shadow: 0px 0px 20px rgba(#000,0.3);
	}
	@include media-breakpoint-up(xl) {
		width: 600px;
		height: 372px;
		// box-shadow: 0px 0px 20px rgba(#000,0.3);
	}
}

.pattern-container {
	position: absolute;
	top:0;
	content: '';
	width: 900px;
	height: 200px;
	left: -150px;
	background: transparent url('#{$image-path}pattern-1.png') 0 0 no-repeat;
	background-position: center;
	background-size: 883px 181px;

	@include media-breakpoint-up(lg) {
		
	left:-100px;
	}
}


.reference-img-container {
	width: 100%;
	height: 200px;
	background: transparent url('#{$image-path}img-3.jpg') 0 0 no-repeat;
	background-position: center top;
	background-size: 100% auto;
			// box-shadow: 0px 0px 20px rgba(#000,0.3);

	@include media-breakpoint-up(sm) {
		height: 300px;	
	}
		
	@include media-breakpoint-up(md) {
		height: 400px;
	}
	@include media-breakpoint-up(lg) {
		position: absolute;
		background-size: auto 100%;
		right:0;
		width: 500px;
		height: 310px;
		// box-shadow: 0px 0px 20px rgba(#000,0.3);
	}
	@include media-breakpoint-up(xl) {
		width: 650px;
		height: 433px;
		// box-shadow: 0px 0px 20px rgba(#000,0.3);
	}
}

/* 
   ###      ######    ########   ####  ##     ##  ####  ########   ##    ##   
  ## ##    ##    ##      ##       ##   ##     ##   ##      ##       ##  ##    
 ##   ##   ##            ##       ##   ##     ##   ##      ##        ####     
##     ##  ##            ##       ##   ##     ##   ##      ##         ##      
#########  ##            ##       ##    ##   ##    ##      ##         ##      
##     ##  ##    ##      ##       ##     ## ##     ##      ##         ##      
##     ##   ######       ##      ####     ###     ####     ##         ##      
 */



.translate-y {


	&.activity-block {
		margin-top: -50px;

		@include media-breakpoint-up(lg) {
			margin-top: -300px;	
		}
		@include media-breakpoint-up(xl) {
			margin-top: -230px;	
		}
	}
	&.reference-block {


		@include media-breakpoint-down(sm) {
			padding:40px 10px;
		}
			

		@include media-breakpoint-up(md) {
			padding: 40px 80px;
		}
		@include media-breakpoint-up(lg) {
			padding:0;
			margin-top: 300px;	
		}
		@include media-breakpoint-up(xl) {
			margin-top: 480px;	
		}
	}
	
}

.block-border {
	
	border: 1px $color-1 solid;
	padding: 15px 20px;
	@include media-breakpoint-up(sm) {
		padding: 15px 30px;
	}
	@include media-breakpoint-up(md) {
		padding: 30px 50px;
	}

	@include media-breakpoint-up(lg) {
		transform: translateY(0px);
	}
	@include media-breakpoint-up(xl) {
		transform: translateY(50px);	
	}
}

.video-container {
		max-width: 380px;
		margin: 30px auto;
	@include media-breakpoint-up(md) {
		max-width: 500px;
		margin: 0px auto;
		
	}
	@include media-breakpoint-up(lg) {
		width: 450px;
		margin: 0;
	}
	@include media-breakpoint-up(xl) {
		height: auto;
		width: 500px;
	}

	.touchevents & {
		@include media-breakpoint-down(md) {
		 	display: none;
		 } 
	}

	.no-video &,
	.no-videoloop &,
	.no-videoautoplay & {
		video {
			visibility: hidden;
			pointer-events: none;
		}

		background: transparent url('#{$image-path}video-img.jpg') 0 0 no-repeat;
		background-size: cover;
	}
	
}

.video video {
	width: 100%;
	height: auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

/* 
 ######    ########    ######    ########   ####   #######   ##    ##   
##    ##   ##         ##    ##      ##       ##   ##     ##  ###   ##   
##         ##         ##            ##       ##   ##     ##  ####  ##   
 ######    ######     ##            ##       ##   ##     ##  ## ## ##   
      ##   ##         ##            ##       ##   ##     ##  ##  ####   
##    ##   ##         ##    ##      ##       ##   ##     ##  ##   ###   
 ######    ########    ######       ##      ####   #######   ##    ##   
 */

.section-light {
	background-color: $white;
}

.section-medium {
	background-color: $color-3;

	&.bg-pattern {
		background: $white url('#{$image-path}pattern-bg.png') repeat;
		background-position: center;
		background-size: 132px 57px;
	}
}

.section-dark {
	@include media-breakpoint-up(lg) {
		margin-left: 90px;
		position: relative;

		&:before {
			content:''; 
			width: 90px;
			height: 150px;

			position: absolute;
			top:0;
			left: -90px;
			background-color: $color-3; 
		}
	}
	
	background-color: $color-1;
	background: linear-gradient(to bottom right, $color-1 0%, $color-1 30%, rgba(darken($color-1, 20%), 1) 100%);
}


/* 
########   ########   ########   ########   ########   ########   ##    ##    ######    ########   
##     ##  ##         ##         ##         ##     ##  ##         ###   ##   ##    ##   ##         
##     ##  ##         ##         ##         ##     ##  ##         ####  ##   ##         ##         
########   ######     ######     ######     ########   ######     ## ## ##   ##         ######     
##   ##    ##         ##         ##         ##   ##    ##         ##  ####   ##         ##         
##    ##   ##         ##         ##         ##    ##   ##         ##   ###   ##    ##   ##         
##     ##  ########   ##         ########   ##     ##  ########   ##    ##    ######    ########   
 */

.reference-container {

	.item {
		width: 100%;
		margin:10px 0px;
		cursor: pointer;
		background-color: $white;
		border: 2px $color-3 solid;
		transition: border 0.2s ease-in-out;
		display: block;
		overflow: hidden;
		
		@include media-breakpoint-up(lg) {
			height: 78px;
		}
	
		@include media-breakpoint-up(xl) {
			height: 100px;	
		}

		&:hover {
		transition: border 0.2s ease-in-out;
			border: 2px $color-2 solid;

		}

		.img-item {
			width: 100%;
			margin: 0px auto;
			height: auto;
			border:none;
			display: block;
			cursor: pointer;
		}
	}
}




/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/

.btn-download {
	@include input_mobile;
	display: inline-block;
	padding: 20px 30px 20px 70px;
	z-index: 1;
	background-color: $white;
	transition: background-color 0.2s linear;
	border: 1px $color-1 solid;


	@include media-breakpoint-down(sm) {
		position: relative;
		// width: 100%;
	}
	

	@include media-breakpoint-down(md) {
		position: absolute;
		bottom: -30px;
		left: 50%;
		transform: translateX(-50%);
	}
	
	@include media-breakpoint-up(lg) {
		position: absolute;
		top: 50%;
		right: 50px;
		transform: translate(0,-50%);
	}

	.icon-download {
		position: absolute;
		top:55%;
		left: 20px;
		transform: translateY(-50%);
		transition: fill 0.2s linear;
		width: 40px;
		height:40px;
		fill: $color-2;
	}
	.btn-label {
		font-family: $font-reg;
		transition: color 0.2s linear;
		color:$color-1;
	}

	.no-touchevents &:hover,
	.no-touchevents &:focus,
	.touchevents &:active {
		background-color: $color-1;
		transition: background-color 0.2s linear;

		.icon-download {
			transition: fill 0.2s linear;
			fill: $white;
		}
		.btn-label {
			transition: color 0.2s linear;
			color:$white;
		}

	}	
}


.nav-footer {
	z-index:  9; 
	margin-bottom: 20px;


	li {
		display:     inline-block;

		&:after {
			content: '-';
			display: inline-block;
			margin: 0 2px 0 6px;
			color: darken($color-2, 35%);
			vertical-align: top;
		}

		&:last-of-type {
			&:after {
				content: "";
				margin: 0;
			}
		}

	}

	@include media-breakpoint-up(md) {
		margin-bottom: 40px;
	}

	@include media-breakpoint-up(lg) {
		position: absolute;
		bottom:       20px;
		right:        100%;
		width:        50%;
		margin-bottom: 0;
		margin-right: 46px;
		text-align: right;		
	}

	li:after {
		content: '';
	}

	a {
		display: inline-block;
		color: darken($color-2, 35%);
		
		@include media-breakpoint-up(lg) {
			color: darken($color-3, 20%);
		}

		.no-touchevents &:hover,
		.no-touchevents &:focus,
		.touchevents &:active {
			transition: color 0.2s ease-in-out;
			color: $white;
			@include media-breakpoint-up(lg) {
				color: $color-1;
			}
		}
	}


}


.bg-white {
	position: relative;
	
	@include media-breakpoint-up(lg) {
		&:after{
			background-color: $white;
			content:'';
			position: absolute;
			top: 50%;
			left:0;
			right:-15px;
			bottom:0;
		}
	}
}

.footer {
	position: relative;
	@include media-breakpoint-up(md) {
		padding-top: 150px;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 100px;
	}
	
}

.block-footer {
	background: linear-gradient(to bottom right, $color-2 0%, $color-2 30%, rgba(darken($color-2, 20%), 1) 100%);
	background-color: $color-2;
	// overflow: hidden;
	@include media-breakpoint-down(sm) {
		padding: 60px 30px;
	}
}

.icon-mcube {
	border-bottom: none !important;
	display: block;
	z-index:2;

	position: absolute;
	bottom: 10px;
	left: 20px;
	svg {
		fill: darken($color-3, 5%);
		padding-top: 20px;
		width: 30px;
		transition: fill 0.2s ease-in-out;
		height: 60px;

		.no-touchevents &:hover,
		.no-touchevents &:focus,
		.touchevents &:active {
			fill: #ea324c;
			transition: fill 0.2s ease-in-out;
		}
	}

	@include media-breakpoint-down(sm) {
		left: 30px !important;
	}
	@include media-breakpoint-down(md) {
		svg {
			fill: darken($color-2, 20%)
		}
		left : 80px;
	}
		
	
}