html { font-size: 62.5%; }

$font-base: 'nowaylight';
$font-alt:  Georgia, serif;
$font-bold: 'nowaymedium';
$font-reg: 'noway';
$font-icon: 'mcube';
$image-path: '/images/';
$font-path:  '/fonts/';
$svg-path:   '/svg/svg-symbols.svg';
$ease:        cubic-bezier(.215, .61, .355, 1); // a personnaliser

$height : width/2;

$color-1: #294369;
$color-2: #6fc3c7;
$color-3: #f0f0f0;

$color-bg:          #fff;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;