.container.no-gutters,
.container-fluid.no-gutters {
	padding-right: 0;
	padding-left: 0;
}

.row.row--no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.row.row--no-gutters > [class^="col-"],
.row.row--no-gutters > [class*=" col-"] {
	padding-right: 0;
	padding-left: 0;
}