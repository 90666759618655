$cc-color-bg:                 #fff;
$cc-color-border:             #ddd;
$cc-color-text:               #000;
$cc-color-btn:                #7DB537;
$cc-color-link:               #7DB537;
$cc-color-validated-checkbox: #7DB537;


@mixin cc-clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

#cookie-consent, #legals-popup {
	display: none; 
	width: 100%; 
	height: 100%;
	position: fixed;
	top: 0; 
	left: 0; 
	z-index: 1000;

	background: rgba(#000, 0.8);
	
	color: black;
	font-family: Arial;
	font-size: 12px;

	&.fixed {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
	}

	&.fixed.bottom {
		top: auto;
		bottom: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}

	a {
		display: inline-block;
		margin-left: 8px;
		padding: 0 6px;
		color: #fff;

		border-radius: 0px;

		text-decoration: none;
	}

	strong {
		font-weight: 700;
	}

	.cc-modal-inner {
		width: 90%;
		max-width: 600px;
		max-height: 90%;
		margin: 20px auto;
		padding: 0 0 92px 0;
		position: absolute;
		top: 50%;
		left: 50%;

		background: $cc-color-bg;
		border-radius: 3px;

		transform: translate(-50%, -50%);
		font-size: 12px;
		line-height: 1.5;
	}

	.cc-main {
		display: none;
	}

	.cc-param {
		position: relative;
	}

	.cc-header {
		padding: 20px;
		border-bottom: 1px solid $cc-color-border;
		background-color: darken($cc-color-bg, 4%);
	}

	.cc-body {
		display: block;
		width: 100%;
		height: auto;
		// min-height: 150px;
		padding: 20px;
		position: relative;
		overflow-y: scroll;
	}

	.cc-param .cc-body {
		height: calc(100vh - 510px);
		max-height: 290px;
		min-height: 250px;
	}

	.cc-footer {
		width: 100%;
		height: 92px;
		padding: 20px;
		position: fixed;
		bottom: 0;
		left: 0;
		text-align: right;

		background-color: darken($cc-color-bg, 4%);
		border-top: 1px solid $cc-color-border;

		transform: translate(0, 0); // permet de fixer dans le contexte de la modale

		// &.cc-footer--align-right {
		// 	text-align: right;
		// }
	}

	.cc-btn {
		text-align: center;
		display: inline-block;
		max-width: 240px;
		margin: 0 auto 20px auto;
		padding: 15px 20px;

		background-color: $cc-color-btn;
		// border-radius: 3px;
		box-shadow: 0 1px 3px rgba(#000, 0.2);

		text-transform: uppercase;
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.1em;

		transition: background-color 0.2s ease-in-out;

		.no-touchevents &:hover,
		.no-touch &:hover,
		.no-touchevents &:focus,
		.no-touch &:focus,
		.touchevents &:active,
		.touch &:active {
			background-color: lighten($cc-color-btn, 10%);
		}

		&.cc-btn-param {
			color: $cc-color-btn;
		}

		&.cc-btn-done {
			//display: inline-block;
			max-width: 280px;
			margin: 0;
			padding: 15px 20px;
		}
	}

	.cc-btn-param {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;

		color: $cc-color-link;

		transform: translate(0, -50%);
		transition: color 0.2s ease-in-out;

		.no-touchevents &:hover,
		.no-touch &:hover,
		.no-touchevents &:focus,
		.no-touch &:focus,
		.touchevents &:active,
		.touch &:active {
			color: lighten($cc-color-link, 10%);
		}
	}

	.cc-title {
		display: block;
		margin: 0;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: bold;
		text-align: left;

		&.cc-title--left {
			text-align: left;
		}
	}

	.cc-intro {
		display: block;
		padding-bottom: 20px;
		// border-bottom: 1px solid $cc-color-border;
	}

	.cc-type {
		@include cc-clearfix;
		display: block;
		padding: 20px 0;
		// margin: 0 20px;
		position: relative;

		border-top: 1px solid $cc-color-border;

		&:first-of-type {
			border-top: none;
		}

		// &:last-of-type {
		// 	border-bottom: 1px solid #ddd;
		// }

		label {
			display: block;
			width: 80%;

			strong {
				display: block;
				margin-bottom: 10px;
				font-size: 13px;
			}
		}

		input[type="checkbox"] {
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			position: absolute;
			right: 0;
			top: 20px;
			cursor: pointer;

			display: block;
			width: 44px;
			height: 22px;

			background-color: $cc-color-border;
			border-radius: 22px;
			transform: scale(1);

			&:before {
				content: "";
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				top: 2px;
				left: 2px;

				background-color: #fff;
				border-radius: 11px;
				box-shadow: 1px 1px 2px rgba(#000, 0.2);
				transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
			}

			&:after {
				content: "non";
				text-transform: uppercase;
				font-weight: bold;
				font-size: 11px;
				position: absolute;
				color: #444;
				top: 112%;
				left: 50%;
				transform: translateX(-50%);

				
			}

			[lang="en"] &:after { content: "no"; }
			[lang="es"] &:after { content: "no"; }
			[lang="de"] &:after { content: "nicht"; }

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&:checked {
				background-color: $cc-color-validated-checkbox;
				&:before {
					transform: translateX(22px);
				}

				&:after {
					content: "oui";
				}
				
				[lang="en"] &:after { content: "yes"; }
				[lang="es"] &:after { content: "si"; }
				[lang="de"] &:after { content: "ja"; }
			}
		}
	}
}

.cc-alert {
	display: block;
	padding: 20px 20px 20px 20px;

	background-color: $cc-color-border;
	color: $cc-color-text;

	a {
		color: $cc-color-text !important;
		text-decoration: underline;
		border: none !important;
	}

	&.cc-video {

	}
}