/*
Variable du path vers le dossier fonts du theme : #{$font-path}

eg.
@font-face {
    font-family: 'maTypo';
    src: url('#{$font-path}maTypo.eot');
    src: url('#{$font-path}maTypo.eot') format('embedded-opentype'),
         url('#{$font-path}maTypo.woff2') format('woff2'),
         url('#{$font-path}maTypo.woff') format('woff'),
         url('#{$font-path}maTypo.ttf') format('truetype'),
         url('#{$font-path}maTypo.svg#maTypo') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/


@font-face {
    font-family: 'nowaybold';
    src: url('../fonts/noway-bold-webfont.eot');
    src: url('../fonts/noway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-bold-webfont.woff2') format('woff2'),
         url('../fonts/noway-bold-webfont.woff') format('woff'),
         url('../fonts/noway-bold-webfont.ttf') format('truetype'),
         url('../fonts/noway-bold-webfont.svg#nowaybold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nowaylight';
    src: url('../fonts/noway-light-webfont.eot');
    src: url('../fonts/noway-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-light-webfont.woff2') format('woff2'),
         url('../fonts/noway-light-webfont.woff') format('woff'),
         url('../fonts/noway-light-webfont.ttf') format('truetype'),
         url('../fonts/noway-light-webfont.svg#nowaylight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nowaymedium';
    src: url('../fonts/noway-medium-webfont.eot');
    src: url('../fonts/noway-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-medium-webfont.woff2') format('woff2'),
         url('../fonts/noway-medium-webfont.woff') format('woff'),
         url('../fonts/noway-medium-webfont.ttf') format('truetype'),
         url('../fonts/noway-medium-webfont.svg#nowaymedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'noway';
    src: url('../fonts/noway-regular-webfont.eot');
    src: url('../fonts/noway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-regular-webfont.woff2') format('woff2'),
         url('../fonts/noway-regular-webfont.woff') format('woff'),
         url('../fonts/noway-regular-webfont.ttf') format('truetype'),
         url('../fonts/noway-regular-webfont.svg#nowayregular') format('svg');
    font-weight: normal;
    font-style: normal;

}